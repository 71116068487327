import React, { useEffect } from "react"
import { Script } from "gatsby"

import * as styles from "../components/index.module.css"

const IndexPage = () => {
  useEffect(() => {
    console.log("hi")
  }, [])
  return (
    <>
      <Script
        src="https://mbshighway.com/scripts/plugins/widgetRender.js"
        strategy="idle"
      />
      <div
        id="widgetData"
        data-encoded="eyJzcmMiOiJodHRwczovL21ic2hpZ2h3YXkuY29tL2ltYWdlcy9mcm9udGVuZC91c2VyRGF0YS82NTE0NDA3OC1iMTI5LTQwNGQtYjcxNi1kZGQ5Yjc2MWZkNWEvaGVhZGVyL2hlYWRzaG90LnBuZz92PTI3MSIsIkxPZW1haWwiOiJhbGJlcnRvZ2FtZXpAYXRsYW50aWNiYXkuY29tIiwiaGVhZGVyRGV0YWlscyI6eyJuYW1lIjoiQWxiZXJ0byBHYW1leiIsImVtYWlsIjoiYWxiZXJ0b2dhbWV6QGF0bGFudGljYmF5LmNvbSIsIm51bWJlciI6IjI0MDk5NDc4ODMiLCJjb21wYW55IjoiQXRsYW50aWMgQmF5IE1vcnRnYWdlIEdyb3VwIiwiY29icmFuZENvZGUiOm51bGwsImNvYnJhbmRFbWFpbCI6IiIsInJlcXVlc3RDb2RlIjoiMTA5ZTFmYjAtMGU1YS00Njk4LThjZDktMTZkNTMwZDdmZDk4In0sInNldHRpbmdzIjp7ImNvcnAiOnRydWUsImVtYWlsIjp0cnVlLCJuYW1lIjp0cnVlLCJwaG90byI6dHJ1ZSwiYXZtIjp0cnVlLCJ1cmwiOiJodHRwczovL3d3dy5hYm1nLTEuZGV2LyJ9fQ=="
      ></div>
    </>
  )
}

export default IndexPage
